<template>

  <v-app-bar id="app-bar"
      elevation="4">
    <v-list-item class="text-light-blue" style="padding-top: 24px">
      <v-list-item-content>
        <v-list-item-title class="text-h6" color="info">GPS AIS </v-list-item-title>
        <v-list-item-subtitle theme="dark" class="text-grey-darken-1">{{ user.username }}</v-list-item-subtitle>
      </v-list-item-content>
      <br/>
    </v-list-item>
    <router-link to="/" style="padding-left: 50px">
      <v-btn color="info">Map
      </v-btn>
    </router-link>
    <router-link to="/listIncidents">
      <v-btn color="info">Incidents
      </v-btn>
    </router-link>
    <router-link to="/subscription">
      <v-btn color="info">Subscription
      </v-btn>
    </router-link>
    <router-link to="/graph">
      <v-btn color="info">Graph
      </v-btn>
    </router-link>
    <SignOut style="padding-right: 16px"></SignOut>
  </v-app-bar>
</template>

<script>
import {Auth} from "aws-amplify";
import SignOut from "@/components/SignOut";

export default {
  name: "NavigationComponent",
  components: { SignOut},
  data() {
    return {
      user: 'test'
    }
  },
  mounted() {
    Auth.currentUserInfo().then(result => {
      console.log(result)
      this.user = result
    })
  }
}
</script>

<style scoped>

</style>