import { DAY_RANGE } from './constants';
export function generateChartData(labelArray, datasets){
    return {
        labels: labelArray,
        datasets: datasets.map(dataset => ({
            type: 'line',
            label: dataset.label,
            data: dataset.data,
            borderColor: dataset.borderColor,
            backgroundColor: dataset.backgroundColor,
            yAxisID: dataset.yAxisID || 'y1',
            tension: 0.2,
            borderWidth: 1,
            pointRadius: 0,
            hidden: dataset.hidden || false,
            fill: dataset.fill || true,
            snapGaps: dataset.snapGaps || true,
        })),
    };
}
export function generateGraphOptions(args){
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x:{
          type: 'time',
          time: {
            unit: 'month',
            displayFormats: {
              hour:'HH:mm',
              day: 'dd MMM, yy',
              week: 'dd MMM, yy'
            },
            tooltipFormat: 'dd MMM yyyy HH:mm',
          },
        },
        y1: {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
          grid: {
            drawOnChartArea: false 
          }
        },
        y2: {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
          grid: {
            drawOnChartArea: false 
          }
        },
      },
      plugins: {
        title:{
            display: true,
            text: args.title,
            font: {
              size: 20
            }
          },
          legend:{
            display: false,
          },
          CrosshairPlugin:true,
          tooltip:{
            mode: 'index',
            intersect: false,
            position: 'custom',
          },
          zoom: {
            limits:{
                x:{
                    min: '',
                    max: '',
                    minRange: DAY_RANGE
                }
              },
            zoom: {
              wheel: {
                enabled: true, 
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
              onZoom: args.onZoom,
            },
            pan: {
              enabled: true,  
              mode: 'x',
              onPanStart:args.onPanStart,
              onPan: args.onPan,
              onPanComplete: args.onPanComplete,
            }
          },
        }
    }
}


export function dateToString(date){
    return date.toISOString().split('T')[0];
}