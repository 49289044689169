import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            token: null,
            isAuthenticated: false
        };
    },
    mutations: {
        setIsAuthenticated(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated;
        },
        setToken(state, token) {
            state.token = token;
        }
    },
    actions: {
        setIsAuthenticated({ commit }, isAuthenticated) {
            commit('setIsAuthenticated', isAuthenticated);
            if(isAuthenticated){
                console.log("User is authenticated");
            }
        },
        setToken({ commit }, token) {
            commit('setToken', token);
        },
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getIsAuthenticated(state) {
            return state.isAuthenticated;
        }
    },
});
export default store;

