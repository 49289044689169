export const CrosshairPlugin = {
    id: 'CrosshairPlugin',

    beforeDatasetsDraw(chart) {
        const { ctx, chartArea: { top, bottom, left, right } } = chart;
        const crosshair = chart.$crosshair;

        if (crosshair && crosshair.visible && crosshair.x >= left && crosshair.x <= right) {
            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = 'rgba(0,0,0,0.7)';
            ctx.lineWidth = 1;
            ctx.moveTo(crosshair.x, top);
            ctx.lineTo(crosshair.x, bottom);
            ctx.stroke();
            ctx.restore();
        }
    },
    afterEvent(chart, args) {
        const event = args.event;
        if(event.native.isTrusted){
            let syncData = {chartId: chart.id, type: event.type};
            if(!args.inChartArea) syncData.type = 'mouseout';

            if(syncData.type === 'mouseout'){
                chart.$crosshair = {
                    x: null,
                    visible: false,
                };
                chart.canvas.dispatchEvent(new MouseEvent('mouseout'));
            }
            else if (syncData.type === 'mousemove' || syncData.type === 'click') {
                const { chartArea: {  left, right } } = chart;
                const canvasX = event.x;
        
                chart.$crosshair = {
                    x: canvasX,
                    visible: canvasX >= left && canvasX <= right,
                };
                if (canvasX >= left && canvasX <= right) {
                    syncData = {...syncData, canvasX, clientX: event.native.clientX };
                }
                chart.draw();
            }
            window.dispatchEvent(new CustomEvent('sync-event', { detail: syncData }));
        }
    }
};