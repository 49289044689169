<template>
    <v-main>
      <GraphFilters :dataset="chartData" @labelSelect="labelSelect" v-if="loaded"/>
      <div class="sub-navbar" v-if="loaded">
        <v-tooltip text="Show daily data for the last year from today" location="bottom">
          <template v-slot:activator="{ props }">
            <button v-bind="props" @click="showGraph(1)" :class="{ active: activeGraph === 1 }">Last Year</button>
          </template>
        </v-tooltip>
        <v-tooltip text="Show hourly data for the last week from today" location="bottom">
          <template v-slot:activator="{ props }">
            <button  v-bind="props" @click="showGraph(2)" :class="{ active: activeGraph === 2 }">Last Week</button>
          </template>
        </v-tooltip>
        <Datepicker class="date-picker" v-model="dateRange" range type="date" :max-date="new Date()" ></Datepicker>
      </div>

      <Line  ref="topChart" :chart-data="chartData.top" :height="300" :chart-options="optionsTop" v-if="loaded"/>
      <Line  ref="bottomChart" :chart-data="chartData.bottom" :height="300" :chart-options="optionsBottom" v-if="loaded"/>

      <div class="center" v-if="!loaded">
        <v-progress-circular align-center indeterminate color="primary"></v-progress-circular>
      </div>
    </v-main>
</template>
<script>
  import { Line } from 'vue-chartjs';
  import zoom from 'chartjs-plugin-zoom';
  import Datepicker from '@vuepic/vue-datepicker';
  import authorizedFetch from '@/services/AuthorizedFetch';
  import GraphFilters from '@/components/GraphFilters.vue';
  import { CrosshairPlugin } from '@/plugins/CrosshairPlugin';
  import { DAY_RANGE, HOUR_RANGE, WEEK_RANGE, YEAR_RANGE } from '@/utils/constants';
  import { dateToString, generateChartData, generateGraphOptions } from '@/utils/graphUtils';
  import {
    Chart as ChartJS, Title, Tooltip, Legend, LineElement,
    LinearScale, PointElement, TimeScale, Filler 
  } from 'chart.js'

  import 'chartjs-adapter-date-fns';
  import '@vuepic/vue-datepicker/dist/main.css'
    
  ChartJS.register(
    Title, Tooltip, Legend, LineElement, LinearScale,
    PointElement, TimeScale, zoom, CrosshairPlugin, Filler
  )

  Tooltip.positioners.custom = function (items, chart) {
    const frozenTooltip = this.chart.$frozenTooltip;
    if(frozenTooltip){
      return {
        x: frozenTooltip.x,
        y: frozenTooltip.y
      };
    }
    return Tooltip.positioners.nearest(items,chart);
  };

  export default {
    // TODO: Sjekk hvorfor tooltip blir igjen etter pan. Hvorfor den ikke reagerer på liten hover
    name: 'GraphView',
    components: {
      Line,
      GraphFilters,
      Datepicker
    },
    data() {
      return {
        loaded: false,
        chartData:{},
        activeGraph: 1,
        optionsTop: this.generateOptions('Incidences and Anomalies'),
        optionsBottom: this.generateOptions('Distinct MMSI and Anomalies'),
        dateRange:{},
      }
    },
    methods: {
      getChartRefs(){
        return [this.$refs.topChart?.chart, this.$refs.bottomChart?.chart];
      },
      generateURL(start, end, mode ){
        if(mode === 'daily'){
          return `/stats/daily/${dateToString(start)}/${dateToString(end)}`
        }
        return `/stats/hourly/${start.toISOString()}/${end.toISOString()}`
      },
      showGraph(graph) {
        if (this.activeGraph === graph) return;

        this.activeGraph = graph;
        const today = new Date();
        let mode, url, start;
        if(graph === 1){
          start = new Date(today - YEAR_RANGE);
          mode = 'daily';   
        }else if(graph === 2){
          start = new Date(today - WEEK_RANGE);
          mode = 'hourly';
        }
        url = this.generateURL(start, today, mode);
        authorizedFetch(url)
          .then((response) => response.json())
          .then((data) => {
            this.setChartData(data,mode)
            this.loaded = true
          });
      },
      updateTimeScale(chart, onlyZero = false){
        if(!chart) return;

        const xScale = chart.scales.x;
        const difference = xScale.max - xScale.min;
        if(difference < DAY_RANGE*2){
            xScale.options.time.unit = 'hour';
        }else if(difference < DAY_RANGE*60){
            xScale.options.time.unit = 'day';
        }else if (difference < DAY_RANGE*200){ 
            xScale.options.time.unit = 'week';
        }else{
            xScale.options.time.unit = 'month';
        }
        
        // If all values are zero, update('none') will not work properly
        onlyZero ? chart.update() : chart.update('none');
        
      },
      labelSelect(info){
          if(info.chart === 1){
            this.chartData.top.datasets[info.index].hidden = !this.chartData.top.datasets[info.index].hidden;
          }else{
            this.chartData.bottom.datasets[info.index].hidden = !this.chartData.bottom.datasets[info.index].hidden;
          }
      },
      setChartData(data, mode) {
        if (!data || data.length === 0) {
          return;
        }
        let labelArray = [];
        let incidentValues = [];
        let anomalyValues = [];
        let distinctMMSI = [];
        let distinctMMSIAnomalies = [];

        let lastTime = mode === 'daily' ? new Date(data[0].localDate) : new Date(data[0].endTime);
        const timeDelta = mode === 'daily' ? DAY_RANGE : HOUR_RANGE;

        for (let i = 0; i < data.length; i++) {
          let feature = data[i];

          let now = mode === 'daily' ? new Date(feature.localDate) : new Date(feature.endTime);
          while(now - lastTime > timeDelta){
            lastTime = new Date(lastTime.getTime() + timeDelta);
            labelArray.push(mode === 'daily' ? dateToString(lastTime) : lastTime.toISOString().split('.')[0]+'Z');
            incidentValues.push(0);
            anomalyValues.push(0);
            distinctMMSI.push(0);
            distinctMMSIAnomalies.push(0);
          }

          labelArray.push(feature.localDate || feature.endTime);
          incidentValues.push(feature.incidents);
          anomalyValues.push(feature.anomalies);
          distinctMMSI.push(feature.distinctMmsis);
          distinctMMSIAnomalies.push(feature.distinctMmsisWithAnomaly);
          lastTime = now;
        }

        this.chartData = {
          top: generateChartData(labelArray,[
            {
              label: "Anomaly",
              data: anomalyValues,
              borderColor: 'rgba(55, 148, 148, 1)',
              backgroundColor: 'rgba(55, 148, 148, 0.2)',
            },
            {
              label: "Incidents",
              data: incidentValues,
              borderColor: 'rgba(232, 183, 67, 1)',
              backgroundColor: 'rgba(232, 183, 67, 0.3)',
              yAxisID: 'y2',
            }
          ]),
          bottom: generateChartData(labelArray,[
            {
              label: "Distinct MMSI",
              data: distinctMMSI,
              borderColor: 'rgba(147, 50, 168, 0.5)',
              backgroundColor: 'rgba(147, 50, 168, 0.3)',
            },
            {
              label: "Distinct MMSI with Anomalies",
              data: distinctMMSIAnomalies,
              borderColor: 'rgba(171, 17, 25, 0.5)',
              backgroundColor: 'rgba(171, 17, 25, 0.3)',
              yAxisID: 'y2',
            }
          ])
        }
      
        const startTime = data[0].localDate || data[0].endTime;
        const endTime = data[data.length - 1].localDate || data[data.length - 1].endTime;

        const scale1 = this.optionsTop.scales;
        const scale2 = this.optionsBottom.scales;

        [scale1,scale2].forEach((scale)=>{
          scale.x.min = startTime; scale.x.max = endTime;
          scale.y1.min = scale.y2.min = 0;
        })

        const maxAnomaly = Math.max(...anomalyValues);
        const maxIncident = Math.max(...incidentValues);
        const maxDistinctMMSI = Math.max(...distinctMMSI);
        const maxDistinctMMSIAnomalies = Math.max(...distinctMMSIAnomalies);

        scale1.y1.max = maxAnomaly+1; scale1.y2.max = maxIncident+1;
        scale2.y1.max = maxDistinctMMSI+1; scale2.y2.max = maxDistinctMMSIAnomalies+1;

        [this.optionsTop, this.optionsBottom].forEach((option) => {
          option.plugins.zoom.limits.x.min = new Date(startTime).getTime();
          option.plugins.zoom.limits.x.max = new Date(endTime).getTime();
          option.plugins.zoom.limits.x.minRange = mode === 'daily'? WEEK_RANGE : DAY_RANGE;
        });

        this.$nextTick(() => {
          const onlyZero = maxAnomaly === 0 && maxIncident === 0 &&
                          maxDistinctMMSI === 0 && maxDistinctMMSIAnomalies === 0;
          this.updateTimeScale(this.getChartRefs()[0],onlyZero);
          this.updateTimeScale(this.getChartRefs()[1],onlyZero);
        });
      },
      generateOptions(title){
        return generateGraphOptions({
          title: title,
          onZoom: ({chart}) =>{
            this.updateTimeScale(chart)
            this.syncZoomAndPan(chart,'zoom')
          },
          onPanStart:({chart})=>{
            const tooltip = chart.tooltip;
            if(tooltip && tooltip.opacity !== 0){
              chart.$frozenTooltip = {
                x: tooltip.caretX,
                y: tooltip.caretY,
              };
            }
          },
          onPan:({chart})=>{
            this.syncZoomAndPan(chart,'pan')
          },
          onPanComplete:({chart})=>{
            delete chart.$frozenTooltip;
            chart.update('none');
          },
        })
      },
      syncZoomAndPan(chart,type){
        const chart1 = this.getChartRefs()[0];
        const chart2 = this.getChartRefs()[1];

        const syncedChart = chart === chart1 ? chart2 : chart1;
        const xScale = chart.scales.x;
        const min = xScale.min;
        const max = xScale.max;

        syncedChart.scales.x.options.min = min;
        syncedChart.scales.x.options.max = max;

        if(type === 'zoom'){
          this.updateTimeScale(syncedChart);
        }else if(type === 'pan'){
          syncedChart.update('none');
        }
      },
      handleSyncEvent(event){
        const { chartId, type, canvasX, clientX} = event.detail;

        this.getChartRefs().forEach((instance) => {
          if (instance.id !== chartId) {
            instance.$crosshair = {
              x: (type === 'mousemove' || type === 'click') ? canvasX : 0,
              visible: (type === 'mousemove' || type === 'click'),
            };
            instance.draw();
            const mouseEvent = new MouseEvent(type, {
              clientX: clientX,
              clientY: instance.id === this.getChartRefs()[0].id ? 200 : 500,
              bubbles: true,
              cancelable: true
            });
            instance.canvas.dispatchEvent(mouseEvent);
            return;
          }
        });
      },
    },

    watch:{
      dateRange(range){
        if (range){
          this.activeGraph = 3;
          const start = range[0];
          const end = range[1];
          let mode = 'hourly';
          let url;

          if(Math.abs(end - start) > 2*WEEK_RANGE) mode = 'daily';

          url = this.generateURL(start, end, mode);
          authorizedFetch(url)
          .then((response) => response.json())
          .then((data) => {
            this.setChartData(data,mode)
            this.loaded = true
          });
        }
      }
    },
    mounted(){
      const today = new Date();
      const lastYear = new Date(today - YEAR_RANGE);
      authorizedFetch(this.generateURL(lastYear, today, 'daily'))
        .then((response) => response.json())
        .then((data) => {
          this.setChartData(data,'daily')
          this.loaded = true
        });
      window.addEventListener('sync-event', this.handleSyncEvent);

    },
    beforeUnmount() {
      this.getChartRefs().forEach((chart) => {
        if (chart) {
          chart.destroy();
          chart = null;
        }
      });
      window.removeEventListener('sync-event', this.handleSyncEvent);
    },
    
  } 
</script>
<style scoped>
  .center {
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .sub-navbar {
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.sub-navbar button {
  background: none;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
}

.sub-navbar button.active {
  border-bottom: 2px solid #ADD8E6;
  font-weight: bold;
}
.date-picker{
  width: 360px;
}
</style>
  